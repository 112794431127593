<template>
    <div class="home-page">

        <div class="d-fex">

            <b-row>

                <b-col cols="8">

                    <div class="h4 mb-4">Мои задачи</div>

                    <div style="max-width: 920px;">
                        <b-row>

                            <template
                                v-if="!isProjectOrganization"
                            >
                                <b-col cols="6">

                                <div
                                    class="nav-link border d-flex align-items-center p-3 mb-3"
                                    @click="$router.push('/purchase-orders')"
                                    style="cursor: pointer"
                                >

                                    <img
                                        src="@/assets/img/icon-list.svg"
                                        class="flex-shrink-0 mr-3"
                                        alt=""
                                        style="width: 60px;"
                                    >

                                    <span class="h4">Заказы на поставку подрядных организаций {{ getQuantity('orders') }}</span>

                                </div>

                                </b-col>

                                <b-col
                                v-if="isProvider"
                                cols="6"
                                >

                                <div
                                    class="nav-link border d-flex align-items-center p-3 mb-3"
                                    @click="$router.push('/provider/purchase-orders')"
                                    style="cursor: pointer"
                                >

                                    <img
                                        src="@/assets/img/icon-list.svg"
                                        class="flex-shrink-0 mr-3"
                                        alt=""
                                        style="width: 60px;"
                                    >

                                    <span class="h4">Заказы поставщику филиалов {{ getQuantity('provider_orders') }}</span>

                                </div>

                                </b-col>

                                <b-col cols="6">

                                <div
                                    class="nav-link border d-flex align-items-center p-3 mb-3"
                                    @click="$router.push('/consignment-notes')"
                                    style="cursor: pointer"
                                >

                                    <img
                                        src="@/assets/img/icon-receipt.svg"
                                        class="flex-shrink-0 mr-3"
                                        alt=""
                                        style="width: 60px;"
                                    >

                                    <span class="h4">Товарные накладные {{ getQuantity('consignments') }}</span>

                                </div>

                                </b-col>

                                <!--                            <b-col-->
                                <!--                                v-if="isProvider"-->
                                <!--                                cols="6"-->
                                <!--                            >-->

                                <!--                                <div-->
                                <!--                                    class="nav-link border d-flex align-items-center p-3 mb-3"-->
                                <!--                                    style="cursor: pointer"-->
                                <!--                                >-->

                                <!--                                    <img-->
                                <!--                                        src="@/assets/img/icon-list.svg"-->
                                <!--                                        class="flex-shrink-0 mr-3"-->
                                <!--                                        alt=""-->
                                <!--                                        style="width: 60px;"-->
                                <!--                                    >-->

                                <!--                                    <span class="h4">Согласование потребности филиалов</span>-->

                                <!--                                </div>-->

                                <!--                            </b-col>-->

                                <b-col
                                v-if="isProvider"
                                cols="6"
                                >

                                <div
                                    class="nav-link border d-flex align-items-center p-3 mb-3"
                                    @click="$router.push('/provider-notifications')"
                                    style="cursor: pointer"
                                >

                                    <img
                                        src="@/assets/img/icon-list.svg"
                                        class="flex-shrink-0 mr-3"
                                        alt=""
                                        style="width: 60px;"
                                    >

                                    <span class="h4">Уведомления о поставке филиалов {{ getQuantity('organization_notifications') }}</span>

                                </div>

                                </b-col>

                                <b-col cols="6">

                                <div
                                    class="nav-link border d-flex align-items-center p-3 mb-3"
                                    @click="$router.push('/consignment-notes-registries')"
                                    style="cursor: pointer"
                                >

                                    <img
                                        src="@/assets/img/icon-draw.svg"
                                        class="flex-shrink-0 mr-3"
                                        alt=""
                                        style="width: 60px;"
                                    >

                                    <span class="h4">Реестры товарных накладных {{ getQuantity('consignment_registers') }}</span>

                                </div>

                                </b-col>

                                <b-col cols="6">

                                <div
                                    class="nav-link border d-flex align-items-center p-3 mb-3"
                                    @click="$router.push('/price-negotiations')"
                                    style="cursor: pointer"
                                >

                                    <img
                                        src="@/assets/img/icon-draw.svg"
                                        class="flex-shrink-0 mr-3"
                                        alt=""
                                        style="width: 60px;"
                                    >

                                    <span class="h4">Согласование цен {{ getQuantity('price_negotiations') }}</span>

                                </div>

                                </b-col>

                                <b-col cols="6">

                                <div
                                    class="nav-link border d-flex align-items-center p-3 mb-3"
                                    @click="$router.push('/registries')"
                                    style="cursor: pointer"
                                >

                                    <img
                                        src="@/assets/img/icon-receipt.svg"
                                        class="flex-shrink-0 mr-3"
                                        alt=""
                                        style="width: 60px;"
                                    >

                                    <span class="h4">Реестры платежей {{ getQuantity('payment_registers') }}</span>

                                </div>

                                </b-col>
                            </template>

                            <b-col cols="6">

                                <div
                                    class="nav-link border d-flex align-items-center p-3 mb-3"
                                    @click="$router.push('/nsi-list')"
                                    style="cursor: pointer"
                                >

                                    <img
                                        src="@/assets/img/icon-list.svg"
                                        class="flex-shrink-0 mr-3"
                                        alt=""
                                        style="width: 60px;"
                                    >
                                    <span class="h4">НСИ {{ getQuantity('request_addition') }}</span>


                                </div>

                            </b-col>

                        </b-row>
                    </div>

                </b-col>

                <b-col cols="4"
                    v-if="!isProjectOrganization"
                >

                    <div class="h4 mb-4">Уведомления</div>

                    <div
                        v-if="notificationsLoading"
                        class="text-center py-5 d-flex justify-content-center"
                    >
                        <b-spinner variant="primary"/>
                    </div>

                    <div v-else>
                        <div
                            v-if="notifications && notifications.length"
                            class="overflow-auto"
                            style="max-height: calc(100vh - 202px)"
                        >

                            <Notification
                                v-for="notification in notifications"
                                :key="notification.id"
                                :item="notification"
                            />

                        </div>

                        <div v-else>
                            <p class="text-muted">На текущий момент уведомлений нет.</p>
                        </div>
                    </div>

                </b-col>

            </b-row>

        </div>

    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Notification from '@/components/UI/Notification'

export default {
    name: "Home",
    components: {
        Notification
    },
    computed: {
        ...mapState({
            quantity: state => state.main.quantity,
            notifications: state => state.notifications.notifications,
        }),
        ...mapGetters([
            'isProvider',
            'isProjectOrganization'
        ]),
        quantity() {
            return this.$store.state.main.quantity
        }
    },
    data: () => ({
        notificationsLoading: true,
        breadcrumbs: [
            {
                title: 'Главная'
            }
        ]
    }),
    methods: {
        getQuantity(item) {
            if (this.quantity[item]) {
                return `(${this.quantity[item]})`
            }
        }
    },
    async mounted() {
        await Promise.all([
            await this.$store.dispatch('getQuantity'),
            await this.$store.dispatch('getNotifications').then(() => this.notificationsLoading = false).catch(() => this.notificationsLoading = false)
        ])
    }
}
</script>

<style scoped>
.nav-link {
    transition: all .3s ease;
}

.nav-link:hover {
    color: #3498DB;
}
</style>
